import { WebStorageStateStore, UserManagerSettings, UserManager, Log } from 'oidc-client-ts';

const userManagerConfig = {
  client_id: '917d570e-828f-4b29-846c-198cd817c295',
  client_secret: 'defaultsecret',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/authorize`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`,
  response_type: 'code',
  scope:
    'openid profile email roles offline_access ' +
    'pano-team.read pano-team.edit pano-team.create ' +
    'pano-team_service.read pano-team_service.grant pano-team_service.revoke ' +
    'pano-team_member.grant pano-team_member.revoke ' +
    'pano-role.read pano-role.create pano-role.edit pano-user.edit ' +
    'maho-licence.read maho-licence.edit maho-workflow.read maho-workerpool.edit maho-workflowstepfilter.edit ' +
    'pano-user_password.reset pano-user_password.change ' +
    'pano-user.read pano-team_invite.grant pano-team.invite_mode ' +
    'echo-commands.read echo-commands.edit echo-application.edit echo-speechrec.live echo-feedback.admin',
  authority: 'https://id.au.lexacom.com',
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
} as UserManagerSettings;

if (process.env.NODE_ENV !== 'production') {
  Log.setLogger(console);
}
const userManager = new UserManager(userManagerConfig);
export default userManager;
